<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">交通规划所</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">交通规划所主要职责：</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要承担编制各层级（总体规划、详细规划）的支撑类交通专项规划（综合交通、公共交通、道路、停车、慢行等）、开展道路、公交、交通枢纽、停车场等设施选址论证，建设项目交通咨询、交通影响评价和优化设计等。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>副所长：金勇（负责人）</b></span> 
        </p>
        <img width="80%" style="margin: 10px 10% 0" src="../../assets/htmlimg/“交通筹备组”大.jpeg" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>